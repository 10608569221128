import { motion } from "framer-motion";
import styled, { keyframes } from "styled-components";
import queries from "../../bin/queries";

export const LandingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 1rem;
  flex-direction: column;
`

const Animation = keyframes`
  0%, 100% {
    transform: translateY(0);
    box-shadow: 0px 0px var(--red), 0px 0px var(--prp);
  } 50% {
    transform: translateY(1rem);
    box-shadow: 10px 10px var(--red), -10px -10px var(--prp);
  }
`
export const LandingAvatar = styled.div`
  width: 300px;
  height: 300px;
  overflow: hidden;
  position: relative;
  border-radius: 50%;
  animation: ${Animation} 3s infinite linear;

  @media (${queries.mob}) {
    width: 250px;
    height: 250px;
  }
`
export const AvatarSrc = styled.img`
  width: 100%;
  transform: translateY(-70px);
`
export const Name = styled(motion.div)`
  font-family: var(--ff-pop);
  font-size: 5rem;
  color: var(--red);
  text-shadow: 5px 5px var(--white);
  letter-spacing: 15px;
  text-align: center;
  span {
    cursor: pointer;
    transition: all 200ms linear;
    :hover {
      color: var(--white);
      text-shadow: 5px 5px var(--red);
    }
  }
  span:nth-child(2) {
    color: var(--white);
    text-shadow: 5px 5px var(--red);
  }

`

export const Logo = styled.img`
  width: 100%;
  pointer-events: none;
  transition: 300ms;
  transform: translateX(${props => props.position.x}px) translateY(${props => props.position.y}px);
`

export const SocialIcons = styled(motion.div)`
  display: flex;
  gap: 2rem;
  @media (${queries.mob}) {
    gap: .5rem;
  }
`
export const SocialLink = styled(motion.a)`
  font-size: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  text-decoration: none;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  transition: all 200ms linear;
  text-shadow: 5px 1px var(--primary);

  :hover {
    color: var(--red);
    text-shadow: 0px 0px var(--primary);
  }

  @media (${queries.mob}) {
    font-size: 1rem;
    width: 50px;
    height: 50px;
  }
`

export const SideImage = styled.div`
  width: 350px;
  height: 350px;
  overflow: hidden;
  position: fixed;
  border-radius: 50%;
  left: -5%;
  bottom: -10%;
  transition: 200ms;
  box-shadow: 5px -5px var(--red);

  img {
    width: 100%;
    object-fit: cover;
  }
  :hover {
    left: 5%;
    bottom: 5%;
  }
  @media (${queries.mob}) {
    width: 100px;
    height: 100px;
    box-shadow: 2px -2px var(--red);
    left: -3%;
    bottom: -3%;
    :hover {
    left: 2%;
    bottom: 2%;
  }
  }
`