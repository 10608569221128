import React, { useState, useEffect } from "react";
import { FramedTag, RouteContainer } from "../../components";
import { Button, Container, Header, Wrapper } from "./styled";
import resumePDF from "../../assets/resume.pdf";
import { Document, Page } from "react-pdf";

export default function Resume() {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [objectUrl, setObjectUrl] = useState();

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    (async () => {
      const response = await fetch("resume.pdf");
      const blob = await response.blob();
      setObjectUrl(window.URL.createObjectURL(blob));
    })();
  }, []);

  return (
    <RouteContainer>
      <Container>
        <Wrapper>
          <Header>
            <FramedTag component="h1">Resume</FramedTag>
          </Header>
          <Document file="/resume.pdf" onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} />
          </Document>

          <Button href={resumePDF}>Download My Resume</Button>
        </Wrapper>
      </Container>
    </RouteContainer>
  );
}
