import styled from "styled-components";
import queries from "../../../bin/queries";

// @media (${queries.mob}) {
// }
export const Container = styled.div`
  font-family: var(--ff-space);
  padding: 2rem;
`
export const Wrapper = styled.div``
export const Header = styled.div`
  display: flex;
  height: 50vh;
  width: 100%;
  position: relative;
  gap: 1rem;
  @media (${queries.mob}) {
    flex-direction: column;
    height: fit-content;
  }
`
export const HeaderTitle = styled.div`
  @media (${queries.mob}) {
    h1 {
      font-size: 1.5rem;
    }
  } 
`
export const HeaderMedia = styled.div`
  height: 100%;
  flex: 0 0 50%;

  img {
    object-fit: cover;
    width: 100%;
  }
`
export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 0 0 50%;
  justify-content: space-evenly;
`
export const ChipsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`
export const ChipTitle = styled.h4`
  color: var(--primary);
`
export const Chips = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
`
export const Chip = styled.div`
  background-color: var(--shadow);
  color: var(--white);
  padding: .2rem .5rem;
  border-radius: .5rem;
`
export const Dates = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: space-between;

  span {
    color: var(--primary);
  }
`

export const Description = styled.div`
  margin-top: 2em;
`
export const DescriptionTitle = styled.h2`
  color: var(--primary);
`
export const DescriptionContent = styled.div`
  margin-left: 1rem;

  ul {
    list-style: disc;
    margin-left: 1rem;
  }
  h1, h2, h3, h4, h5, h6 {
    color: var(--primary);
    padding: .4rem;
  }
  strong {
    color: var(--primary);
  }
`

export const Actions = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  gap: 2rem;
  background-color: var(--shadow);
  padding: 1rem;

`
export const Button = styled.a`
  text-decoration: ${(props) => props.public ? 'none' : 'line-through'};
  transition: all 500ms ease;

  :hover {
    filter: brightness(.7);
    transform: scale(1.1);
  }
`