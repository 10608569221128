import About from './About'
import Contact from './Contact'
import Landing from './Landing'
import Toolbox from './Toolbox'
import Error from './Error'
import Resume from './Resume'
import ProjectsPage from './Projects'
import ProjectChildPage from './Projects/Project'

const routes = [
  {
    name: "Home",
    element: <Landing />,
    slug: "/"
  },
  {
    name: "Projects",
    element: <ProjectsPage />,
    slug: "/projects"
  },
  {
    name: "Projects",
    element: <ProjectChildPage />,
    slug: "/projects/:slug"
  },
  {
    name: "Toolbox",
    element: <Toolbox />,
    slug: "/toolbox"
  },
  {
    name: "Contact",
    element: <Contact />,
    slug: "/contact"
  },
  {
    name: "About",
    element: <About />,
    slug: "/about"
  },
  {
    name: "Resume",
    element: <Resume />,
    slug: "/resume"
  },
  {
    name: "Error",
    element: <Error />,
    slug: "*"
  },
]
export default routes;