import React, { useRef, useState } from "react";
import {
  Container,
  Navigation,
  Icons,
  RouteItem,
  RoutesContainer,
  RoutesWrapper,
  Wrapper,
  NavigationIcons,
} from "./styled";
import Routes from "./routes.json";
import { NavLink } from "react-router-dom";
import SocialMedia from "../../routes/Landing/links";

function Header() {
  const [menu, setMenu] = useState(false);
  const [sticky, setSticky] = useState(false);
  const ref = useRef(null);
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      zIndex: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };
  const item = {
    hidden: { opacity: 0, x: "-100%" },
    show: { opacity: 1, x: "0" },
  };

  document.addEventListener("scroll", () => {
    console.log(window.scrollY);
    if (window.scrollY > 200) setSticky(true);
    else setSticky(false);
  });

  return (
    <Container>
      <Wrapper>
        <Navigation ref={ref} className={sticky && "sticky"}>
          <NavigationIcons>
            {SocialMedia.map((x) => (
              <a key={x.href} rel="noreferrer" target="_blank" href={x.href} className={x.icon}></a>
            ))}
          </NavigationIcons>
          <Icons
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={() => setMenu((state) => !state)}
            className={menu ? "fas fa-times" : "fas fa-bars"}></Icons>
        </Navigation>
        <RoutesContainer transition={{ duration: 0.5 }} animate={menu ? "show" : "hidden"} variants={container}>
          <RoutesWrapper>
            {Routes.map(({ slug, name, description }, key) => (
              <RouteItem variants={item} key={key}>
                <NavLink data-text={description} to={slug} onClick={() => setMenu(false)}>
                  {name}
                </NavLink>
              </RouteItem>
            ))}
          </RoutesWrapper>
        </RoutesContainer>
      </Wrapper>
    </Container>
  );
}

export default Header;
