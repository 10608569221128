import { motion } from "framer-motion";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export default function SideBackButton() {
  const navigate = useNavigate();

  const onBack = () => {
    navigate(-1);
  }

  return (
    <Container onClick={onBack}>
      <i className="fa-solid fa-arrow-left"></i>
      <span>Back</span>
    </Container>
  );
}

export const Container = styled(motion.div)`
  position: fixed;
  top: 25%;
  left: 0;
  background: var(--primary);
  color: var(--white);
  padding: 0.2rem 0.8rem;
  border-radius: 0 1rem 1rem 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  z-index: 100;
  gap: 0.5rem;
  transition: all 500ms ease;

  :hover {
    filter: brightness(.8);
    transform: scale(1.2);
  }
  :active {
    filter: brightness(.6);
    transform: scale(1) translateX(-10px);
  }
`;
