import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FramedTag, Loader, RouteContainer, useProjects } from "../../components";
import {
  Actions,
  Button,
  Card,
  CardsContainer,
  Container,
  Content,
  DisabledButton,
  Error,
  Filter,
  FilterAction,
  Header,
  Headline,
  Thumbnail,
  Title,
  Wrapper,
} from "./styled";

export default function Project() {
  const [items, setItems] = useState([]);
  const [filter, setFilter] = useState("");
  const navigate = useNavigate();
  const { projects } = useProjects();

  useEffect(() => {
    setItems(projects.map((x) => x.node));
  }, [projects]);

  function onFilter(name) {
    setFilter(name);
    if (!name) navigate("");
    else navigate(`?filter=${name.toLowerCase()}`);
  }

  useMemo(() => {
    if (!filter) return setItems(projects.map((x) => x.node));
    setItems(projects.map((x) => x.node).filter((x) => x.operated?.includes(filter)));
  }, [filter]);

  const _renderFilters = useCallback(
    (item) => {
      return (
        <FilterAction className={item.key === filter && "active"} key={item.key} onClick={() => onFilter(item.key)}>
          {item.name}
        </FilterAction>
      );
    },
    [filter]
  );

  return (
    <Container>
      <Wrapper>
        <Header>Projects</Header>
        <Filter>
          <FilterAction onClick={() => onFilter(null)}>All</FilterAction>
          {filters.map(_renderFilters)}
        </Filter>
        <RouteContainer>
          {projects ? (
            <CardsContainer>
              {items?.map((item, index) => (
                <RenderCard item={item} index={index} key={item.id} />
              ))}
            </CardsContainer>
          ) : (
            <Error>
              <h3>Something went wrong.</h3>
              <p>Sorry, Something went wrong while loading projects try again later or you can report the issue.</p>
            </Error>
          )}
        </RouteContainer>
      </Wrapper>
    </Container>
  );
}

const filters = [
  {
    name: "Mobile apps",
    key: "Mobile",
  },
  {
    name: "Web apps",
    key: "Website",
  },
  {
    name: "API",
    key: "API",
  },
  {
    name: "Others",
    key: "Other",
  },
];

function RenderCard({ item, index }) {
  const [loading, setLoading] = useState(false);
  const onLoad = () => {
    setLoading(false);
  };

  return (
    <Card
      key={item.id}
      initial={{ opacity: 0, x: -300 }}
      whileInView={{
        opacity: [0, 0, 1],
        x: index % 2 === 0 ? [300, 100, 0] : [-300, -100, 0],
      }}
      transition={{ duration: 0.5 }}
    >
      {loading && <Loader />}
      <Thumbnail style={{ display: loading ? "none" : "block" }}>
        <img onLoad={onLoad} src={item?.thumbnail?.url} alt="" />
      </Thumbnail>
      <Content>
        <Title>{item?.title}</Title>
        <Headline>{item?.headline}</Headline>
        <Actions>
          <Button to={`${item?.slug}`}>More Details</Button>
          {item?.github ? (
            <Button to={`${item?.github}`}>Source Code</Button>
          ) : (
            <DisabledButton>Code Not Available</DisabledButton>
          )}
        </Actions>
      </Content>
    </Card>
  );
}
