import React, { useRef, useState, useEffect } from "react";
import { LandingContainer, Logo, Name, SocialIcons, SocialLink } from "./styled";
import SocialMedia from "./links";
import LogoName from "../../assets/LogoName.png";

export default function Landing() {
  const scrollRef = useRef(null);
  const [position, setPosition] = useState({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    function onMouseLeave() {
      setPosition({ x: 0, y: 0 });
    }
    document.addEventListener("mouseleave", onMouseLeave);
    return () => document.removeEventListener("mouseleave", onMouseLeave);
  }, []);

  function onMouseMove(e) {
    const x = (e.clientX * -1) / 15;
    const y = (e.clientY * -1) / 15;
    setPosition({ x, y });
  }

  return (
    <LandingContainer ref={scrollRef}>
      <Name initial={{ opacity: 0 }} whileInView={{ opacity: [0, 0, 1] }} transition={{ duration: 1 }}>
        <Logo position={position} src={LogoName} alt="OSMX" />
      </Name>
      <SocialIcons whileInView="onscreen" initial="offscreen" variants={container}>
        {SocialMedia.map(({ href, icon }) => (
          <SocialLink
            variants={item}
            target="_blank"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            key={href}
            href={href}
            className={icon}
          />
        ))}
      </SocialIcons>
    </LandingContainer>
  );
}

const container = {
  offscreen: { opacity: 0 },
  onscreen: {
    opacity: 1,
    transition: {
      staggerChildren: 0.3,
    },
  },
};

const item = {
  offscreen: {
    opacity: 0,
    y: 200,
  },
  onscreen: {
    opacity: 1,
    y: 0,
  },
};
