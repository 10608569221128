import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import styled from "styled-components";
import queries from "../../bin/queries";

// @media (${queries.mob}) {
// }

export const Container = styled.div``
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    font-size: var(--fs-header);
    color: var(--white);
  }
`
export const Header = styled.p`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20rem;
  font-weight: bold;
  opacity: 0.1;
  color: var(--gray);
`
export const Error = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
`
export const Filter = styled.div`
  display: flex;
  gap: 2rem;
`
export const FilterAction = styled.div`
  color: var(--white);
  padding: .5rem 2rem;
  border-radius: 1rem;
  box-shadow: 0px 0px 50px #1f1a2a, 2px 2px 2px var(--primary);
  font-family: var(--ff-space);
  transition: 500ms;
  :hover {
    transform: scale(1.2);
    box-shadow: 0px 0px 50px #1f1a2a, 3px 3px 2px var(--primary);
    filter: brightness(0.8);
  }
  &.active {
    background-color: var(--primary);
    margin: 0 1rem;
    box-shadow: 0px 0px 50px #1f1a2a, 3px 3px 2px var(--primary);
    filter: brightness(0.8);
  }
`


export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media (${queries.mob}) {
    gap: 2rem;
  }
`
export const Card = styled(motion.div)`
  display: grid;

  grid-template-columns: 1.5fr 1fr;
  grid-template-rows: 1fr;
  grid-auto-flow: row dense;

  align-items: center;
  grid-gap: 1rem;
  height: 400px;
  width: 80dvw;
  position: relative;
  

  :nth-child(even) {
    direction: rtl;
    grid-template-columns: 1.5fr 1fr
  }


  @media (${queries.mob}) {
    grid-template-columns: 1fr;

    :nth-child(even) {
    flex-direction: column;
  }
  }
`
export const Thumbnail = styled.div`
  height: 90%;
  position: relative;

  img {
    object-fit: fill;
    width: 100%; 
    height: 100%;
    position: relative;
  }
  ::after {
    content: "hover to reveal";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .7);
    color: var(--white);
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s ease;
  }
  :hover {
    ::after {
      opacity: 0;
    }
  }
`

export const Content = styled.div`
  direction: ltr;
  flex: 1;
  color: var(--white);
  position: relative;
  display: flex;
  flex-direction: column;
  gap: .5rem;
  font-family: var(--ff-space);
`
export const Title = styled.h2`
  @media (${queries.mob}) {
    font-size: 1.1rem;
  }
`
export const Headline = styled.div`
  background-color: #1f1a2a;
  padding: 1rem;
  border-radius: .3rem;
  box-shadow: 0px 0px 50px #1f1a2a, 2px 2px 2px var(--primary);
`
export const Actions = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
`
export const Button = styled(Link)`
  background-color: var(--primary);
  padding: .5rem 1rem;
  border-radius: .3rem;
  box-shadow: 0px 0px 50px #1f1a2a, 2px 2px 2px var(--primary);
  color: var(--white);
  text-decoration: none;
  font-weight: 600;
  transition: all .2s ease-in-out;
  :hover {
    box-shadow: 0px 0px 50px #1f1a2a, 4px 4px 2px var(--primary);
    transform: scale(1.1);
    filter: brightness(.8);
  }
  @media (${queries.mob}) {
    padding: .5rem .5rem;
  }
`
export const DisabledButton = styled.div`
  background-color: var(--primary);
  padding: .5rem 1rem;
  border-radius: .3rem;
  box-shadow: 0px 0px 50px #1f1a2a, 2px 2px 2px var(--primary);
  color: var(--white);
  text-decoration: none;
  font-weight: 600;
  filter: brightness(.4);
`