import React from "react";
import styled from "styled-components";

export default function FramedTag({ children, component }) {
  return (
    <FramedContainer>
      <FramedSpan>&lt;{component}&gt;</FramedSpan>
      <FramedContent>{children}</FramedContent>
      <FramedSpan>&lt;/{component}&gt;</FramedSpan>
    </FramedContainer>
  );
}

export const FramedContainer = styled.div`
  display: flex;
  align-items: baseline;
`;
export const FramedContent = styled.div``;
export const FramedSpan = styled.span`
  color: var(--primary);
  font-size: 0.7em;
  opacity: 0.4;
  margin: 0 1rem;
  font-family: var(--ff-lico);
`;
