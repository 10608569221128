import styled, { keyframes } from "styled-components";

const animation = keyframes`
  0% {
    transform: translateY(100vh) scale(0);
  }
  100% {
    transform: translateY(-10vh) scale(1);
  }
  0% {
    transform: translateY(100vh) scale(0);
  }
`

export const Container = styled.div`
  /* width: 100%; */
  height: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
`
export const Bubbles = styled.div`
  position: relative;
  display: flex;
  span {
    margin: 0 .5rem;
    width: 25px;
    height: 25px;
    background-color: var(--primary);
    border-radius: 50%;
    box-shadow: 0 0 10px var(--primary), 0 0 50px var(--primary), 0 0 100px var(--primary);
    animation: ${animation} 0s linear infinite;
    animation-duration: calc(250s / var(--i));
    filter: blur(.4rem);
    :nth-child(even) {
      background-color: var(--prp);
      box-shadow: 0 0 10px var(--prp), 0 0 50px var(--prp), 0 0 100px var(--prp);
    }
  }
`