import React from "react";
import styled from "styled-components";
import { RouteContainer } from "../../components";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <RouteContainer>
      <Container>
        <Wrapper>
          <Icon className="fa-solid fa-triangle-exclamation" />
          <Title>Oh no, something went wrong.</Title>
          <Subject>
            It looks like we are experiencing some problems on our end.
          </Subject>
          <ButtonLink to="/">Back Home</ButtonLink>
        </Wrapper>
      </Container>
    </RouteContainer>
  );
}

export const Container = styled.div`
  width: 100%;
  height: calc(90vh - 80px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const Icon = styled.i`
  font-size: 5rem;
  margin-bottom: 1rem;
  color: var(--white);
`;
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Title = styled.p`
  font-size: 1rem;
  color: var(--white);
  font-weight: bold;
`;

export const Subject = styled.p`
  font-size: 0.8rem;
  color: var(--white);
`;

export const ButtonLink = styled(Link)`
  margin-top: 1rem;
  color: var(--white);
  border: 1px solid var(--white);
  padding: .5rem 1rem;
  border-radius: .5rem;
  transition: 300ms;
  :hover {
    border-color: var(--primary);
    color: var(--primary);
  }
`;
