const SocialMedia = [
  {
    icon: "fab fa-github",
    href: "https://github.com/itsosmx",
  },
  {
    icon: "fas fa-at",
    href: "mailto:itsosmx@gmail.com",
  },
  {
    icon: "fab fa-twitter",
    href: "https://twitter.com/ItsOsMx",
  },
  {
    icon: "fa-brands fa-linkedin",
    href: "https://www.linkedin.com/in/itsosmx/",
  },
];

export default SocialMedia;