import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";

export default function RouteContainer({ children }) {
  const StyledContainer = styled(motion.div)`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
    width: 100%;
    position: relative;
    height: 100%;
  `;
  return (
    <StyledContainer
      animate={{
        opacity: [0, 1],
      }}
      transition={{ duration: 2 }}
    >
      {children}
    </StyledContainer>
  );
}
