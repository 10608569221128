import { request, gql } from 'graphql-request';
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT


export const getProjects = async () => {
  const query = gql`
    query Projects {
      projectsIdConnection(orderBy: developedAt_DESC) {
        edges {
          node {
            title
            github
            createdAt
            description {
              html
              text
            }
            headline
            id
            developedAt
            demo
            slug
            technologies
            thumbnail {
              url
            }
            toolset
            updatedAt
            operated
          }
        }
      }
    }
  `;

  const data = await request(API_ENDPOINT, query);
  return data.projectsIdConnection.edges;
}