import React from "react";
import ProjectsContext from "../Contexts/ProjectsContext";

/**
 * 
 * @returns {React.Context} ProjectsContext
 */
export default function useProjects() {
  return React.useContext(ProjectsContext);
}
