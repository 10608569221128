import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Loader,
  RouteContainer,
  SideBackButton,
  useProjects,
} from "../../../components";
import {
  Container,
  Wrapper,
  Header,
  HeaderMedia,
  HeaderContent,
  Chip,
  Chips,
  ChipTitle,
  ChipsContainer,
  Description,
  DescriptionTitle,
  DescriptionContent,
  Dates,
  HeaderTitle,
  Actions,
  Button,
} from "./styled";

export default function Project() {
  const { projects } = useProjects();
  const location = useLocation();
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const slug = location.pathname.split("/")[2];
    const projectNode = projects?.find((p) => p?.node?.slug === slug);
    setProject(projectNode?.node);
    if (projectNode) setTimeout(() => setLoading(false), 1000);
  }, [location.pathname, projects]);

  console.log(project);

  if (loading) return <Loader fullscreen />;
  else
    return (
      <RouteContainer>
        <Container>
          <Wrapper>
            <Header>
              <HeaderMedia>
                <img src={project?.thumbnail?.url} />
              </HeaderMedia>
              <HeaderContent>
                <HeaderTitle>
                  <h1>{project?.title}</h1>
                  <p>{project?.headline}</p>
                </HeaderTitle>
                <ChipsContainer>
                  <div>
                    <ChipTitle>Technologies Used</ChipTitle>
                    <Chips>
                      {project?.technologies?.map((technology, index) => (
                        <Chip key={index}>{technology}</Chip>
                      ))}
                    </Chips>
                  </div>
                  <div>
                    <ChipTitle>Toolset Used</ChipTitle>
                    <Chips>
                      {project?.toolset?.map((tool, index) => (
                        <Chip key={index}>{tool}</Chip>
                      ))}
                    </Chips>
                  </div>
                </ChipsContainer>
                <Dates>
                  <div>
                    <span>Develope At</span>
                    <p>
                      {project?.developedAt
                        ? new Date(project?.developedAt).toDateString()
                        : "Unknown"}
                    </p>
                  </div>
                  <div>
                    <span>Posted At</span>
                    <p>{new Date(project?.createdAt).toDateString()}</p>
                  </div>
                </Dates>
              </HeaderContent>
            </Header>
            {project?.description?.text && (
              <Description>
                <DescriptionTitle>Description</DescriptionTitle>
                <DescriptionContent
                  dangerouslySetInnerHTML={{
                    __html: project?.description?.html,
                  }}
                />
              </Description>
            )}
            <Actions>
              <Button
                public={project?.github}
                href={project?.github}
                target="_blank"
              >
                <i className="fab fa-github"></i> Github Repo
              </Button>
              <Button href={project?.demo} public={project?.demo}>
                <i className="fas fa-external-link-alt"></i> Live Demo
              </Button>
            </Actions>
          </Wrapper>
        </Container>
        <SideBackButton />
      </RouteContainer>
    );
}
