import React, { useEffect, useState } from "react";
import { Child, Group, GroupContainer, HeaderTitle, ToolboxContainer, ToolboxHeader } from "./styled";
import ToolboxJson from "./Toolbox.json";
import { RouteContainer, FramedTag } from "../../components";

function Toolbox() {
  const [tools, setTools] = useState([]);
  const randomColor = () => {
    const h = Math.floor(Math.random() * 360);
    const s = 45;
    const l = 70;

    return `hsl(${h}, ${s}%, ${l}%)`;
  };

  useEffect(() => {
    const arr = [];
    for (const prop of ToolboxJson) {
      const toolsSet = [];
      for (const child of prop.tools) {
        toolsSet.push({
          ...child,
          color: randomColor(),
        });
      }
      arr.push({ name: prop.name, tools: toolsSet });
    }
    setTools(arr);
  }, []);

  return (
    <RouteContainer>
      <ToolboxContainer>
        <ToolboxHeader>
          <HeaderTitle>
            <FramedTag component="h1">My Toolbox</FramedTag>
          </HeaderTitle>
        </ToolboxHeader>
        {tools.map((prop, index) => (
          <GroupContainer key={index}>
            <h3>
              <FramedTag component="h3">{prop.name}</FramedTag>
            </h3>
            <Group variants={variants} initial="closed" animate="open">
              {prop.tools.map((tool, key) => (
                <Child
                  variants={variantItem}
                  randomColor={tool.color}
                  key={key}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <i className={tool.icon}></i>
                  <span>{tool.name}</span>
                </Child>
              ))}
            </Group>
          </GroupContainer>
        ))}
      </ToolboxContainer>
    </RouteContainer>
  );
}

export default Toolbox;

const variants = {
  open: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1,
    },
  },
  closed: {
    opacity: 0,
    transition: {
      when: "afterChildren",
    },
  },
};
const variantItem = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 0, x: 100 },
};
