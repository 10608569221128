import { motion } from "framer-motion";
import styled from "styled-components";
import queries from "../../bin/queries";

export const ToolboxContainer = styled.div`
  color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
`

export const ToolboxHeader = styled.div``
export const HeaderTitle = styled.h2`
  font-size: var(--fs-header);
`
export const HeaderSubject = styled.span``
export const GroupContainer = styled.div`
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;

`

export const Group = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`

export const Child = styled(motion.div)`
  background-color: rgba(0, 0, 0, 0.2);
  width: 7rem;
  height: 8rem;
  border-radius: 1rem;
  border: 1px solid;
  border-color: ${(props) => props.randomColor};
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: .7rem;
  i {
    color: ${(props) => props.randomColor};
    font-size: 4rem;
  }
  span {
    color: ${(props) => props.randomColor};
  }
`