import styled from "styled-components";

export const Container = styled.div``
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5rem;
`
export const Header = styled.h2`
  font-size: var(--fs-header);
  color: var(--white);
`
export const Button = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  background-color: var(--primary);
  color: var(--white);
  padding: 2rem;
  border-radius: 2rem;
  transition: 300ms;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: .1em;

  :hover {
    transform: scale(1.2);
  }
  :active {
    transform: scale(.9);
  }

`