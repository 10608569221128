import styled, { createGlobalStyle, keyframes } from "styled-components";
import './fonts.css';
import queries from "../bin/queries";
import Background from '../assets/Background.webp'
import { motion } from "framer-motion";
export const BaseLine = createGlobalStyle`

  :root {
    --primary: #e12031;
    --light-blue: #6670e3;
    --gray: #CBD0D8;
    --orange: #ff6663;
    --offWhite: #d1d1d1;
    --white: #F4F3EA;
    --dark: #333D51;
    --yellow: #D3AC2B;
    --light-dark: #18202D;
    --red: #e12031;
    --green: #38c791;
    --dark-mid: #171820;
    --dark-light: #23242e;
    --prp: #781aea;
    --shadow: rgba(0, 0, 0, 0.5);
    --ff-agent: Agent, sans-serif;
    --ff-animal: Animal, sans-serif;
    --ff-newtype: Newtype, sans-serif;
    --ff-play: 'Play', sans-serif;
    --ff-space: 'Space Mono', monospace;
    --ff-rubik: 'Rubik', sans-serif;
    --ff-pop: 'Poppins', sans-serif;
    --ff-shizuru: 'Shizuru', cursive;
    --ff-lico: 'Licorice', cursive;
    --fs-header: 3rem;
  }

  @media (${queries.mob}) {
    :root {
      --fs-header: 2rem;
    }
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  ::-webkit-scrollbar {
    display: none;
  }
  
  html {
    scroll-behavior: smooth;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: var(--ff-pop);
    /* transition: background-color .5s linear; */
    background-color: var(--dark-mid);
    position: relative;
    min-height: 100vh;
    color: var(--white);
    ::after {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      opacity: .4;
      z-index: -1;
      height: 100%;
      background-position: center;
      background-size: cover;
      background-image: url(${Background});
    }
}
  

  ul {
    list-style: none;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
`

export const AppContainer = styled.main`  
`
export const Body = styled.main`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100vw;
  min-height: 70vh;
  margin-top: 2rem;
  margin-bottom: 4rem;
`
const bounce = keyframes`
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(10px); 
  }
`
export const BackToTop = styled(motion.div)`
  position: fixed;
  left: 3%;
  bottom: 5%;
  color: var(--white);
  background-color: var(--primary);
  padding: .5rem .7rem;
  display: grid;
  place-items: center;
  font-size: 1.5rem;
  border-radius: 1rem;
  animation: ${bounce} 2000ms linear infinite ;
  cursor: pointer;

`