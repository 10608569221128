import React, { useRef } from "react";
import emailjs, { init } from "@emailjs/browser";
import {
  ContactContainer,
  ContactForm,
  ContactFormGroup,
  ContactFormInput,
  ContactFormInputArea,
  ContactFormLabel,
  ContactFormSubmit,
  ContactHeader,
} from "./styled";
import { toast } from "react-toastify";
import { RouteContainer, FramedTag } from "../../components";

init("user_9NXJK1vjN75yc1MBmMfng");

export default function Contact() {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm("service_j4t6wga", "template_46xhre6", form.current, "user_9NXJK1vjN75yc1MBmMfng").then(
      (result) => {
        console.log(result.text);
        form.current.reset();
        toast.success("Your message has been received.");
      },
      (error) => {
        console.log(error);
        toast.error("something went wrong, try again later.");
      }
    );
  };
  return (
    <RouteContainer>
      <ContactContainer>
        <ContactHeader>
          <FramedTag component="h1">Contact Me</FramedTag>
        </ContactHeader>
        <ContactForm netlify ref={form} onSubmit={sendEmail}>
          <ContactFormGroup>
            <ContactFormLabel>Name</ContactFormLabel>
            <ContactFormInput name="from_name" required placeholder="type your name." autoComplete="on" />
          </ContactFormGroup>
          <ContactFormGroup>
            <ContactFormLabel>Email</ContactFormLabel>
            <ContactFormInput name="user_email" required placeholder="type your email" autoComplete="on" />
          </ContactFormGroup>
          <ContactFormGroup>
            <ContactFormLabel>Message</ContactFormLabel>
            <ContactFormInputArea
              name="message"
              required
              rows={10}
              placeholder="type your message."
              autoComplete="on"
            />
          </ContactFormGroup>
          <ContactFormSubmit type="submit">Send</ContactFormSubmit>
        </ContactForm>
      </ContactContainer>
    </RouteContainer>
  );
}
