import { Bubbles, Container } from "./styled";

export default function ParticlesBack() {
  // const [position, setPosition] = useState({
  //   x: 0,
  //   y: 0,
  // });

  // useEffect(() => {
  //   function onMouseLeave() {
  //     setPosition({ x: 0, y: 0 });
  //   }
  //   document.addEventListener("mouseleave", onMouseLeave);
  //   return () => document.removeEventListener("mouseleave", onMouseLeave);
  // }, []);

  // function onMouseMove(e) {
  //   const x = -e.clientX / 15;
  //   const y = -e.clientY / 20;
  //   setPosition({ x, y });
  // }

  return (
    <Container>
      <Bubbles>
        {new Array(100).fill(0).map((_, index) => (
          <span key={index} style={{ "--i": Math.floor(Math.random() * 25) + 10 }}></span>
        ))}
      </Bubbles>
    </Container>
  );
}
