import styled from "styled-components";
import queries from "../../bin/queries";

export const AboutContainer = styled.div`
  font-family: var(--ff-space);
  width: 75dvw;
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;
  gap: 3rem;
  @media (${queries.mob}) {
    width: 90%;
  }
`
export const Span = styled.span`
  color: var(--red);
  font-weight: bold;
  transition: 500ms;
  :hover {
    color: var(--white);
  }
`

export const Summary = styled.span`
  color: var(--white);
  text-align: center;
  
`
export const Bio = styled.div`
  width: 100%;
  color: var(--gray);
  line-height: 2rem;
  p {
    margin-top: 1rem;
    /* text-indent: 2rem; */
    span {
      color: var(--red);
      font-weight: bold;
      transition: 500ms;
      :hover {
        color: var(--white);
      }
    }
  }
  @media (${queries.mob}) {
    width: 100%;
    text-align: center;
  }
  a {
  color: var(--red);
  font-weight: bold;
  }
`
