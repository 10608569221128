import React from "react";
import { AboutContainer, Summary, Bio } from "./styled";
import { RouteContainer } from "../../components";

function About() {
  return (
    <RouteContainer>
      <AboutContainer>
        <Summary>
          <h1>Hi, I’m OSMX. Nice to meet you.</h1>
          <span>Self-taught full stack developer designing and developing websites/applications</span>
        </Summary>
        <Bio>
          <h3>My journey: </h3>
          <p style={{ textIndent: "2rem" }}>
            My journey began in 2015 as a blogger, where I shared my passion for technology through writing technical
            articles. But as I delved deeper into the world of blogging, I became fascinated with the idea of creating
            my own website. And so, I embarked on a journey of self-discovery, learning <span>HTML</span>,{" "}
            <span>CSS</span>, and <span>JavaScript</span> to bring my vision to life.
          </p>

          <p>
            As I experimented with building simple websites, I found myself asking, "What else can I do?" My curiosity
            led me to explore APIs, and that's when everything changed. I discovered <span>Node.js</span> and began
            developing Discord Applications (Bots) using Discord API, which proved to be a game-changer for me. The idea
            of being able to create something that people could use and enjoy was incredibly satisfying.
          </p>

          <p>
            With each new project, I gained more experience and skills. I taught myself <span>Mongoose</span>,{" "}
            <span>React.js</span>, <span>React Native</span>, <span>Python</span>, <span>Kotlin</span>, and other
            technologies, constantly expanding my toolbox. One of my proudest achievements was creating a Discord bot
            that could do anything the Discord API offered. The process was challenging, but it taught me invaluable
            lessons about determination, patience, and creativity.
          </p>

          <p>
            As I continue on my journey, I'm excited to see where it will take me next. I believe that learning is a
            lifelong process, and I'm always seeking new challenges to broaden my horizons. I'm passionate about using
            technology to make a positive impact, and I'm committed to creating innovative solutions that enhance
            people's lives.
          </p>
        </Bio>
      </AboutContainer>
    </RouteContainer>
  );
}

export default About;
