import styled from "styled-components";
import queries from "../../bin/queries";

export const ContactContainer = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;
  gap: 3rem;
  @media (${queries.mob}) {
    width: 100%;
  }
`
export const ContactHeader = styled.h2`
  font-size: var(--fs-header);
  color: var(--white);
`
export const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: var(--white);
  width: 70%;
`
export const ContactFormGroup = styled.div`
  display: flex;
  gap: 1rem;
  margin: 1rem 0;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`
export const ContactFormInput = styled.input`
  outline:  none;
  border: none;
  background-color: rgba(0,0, 0, 0.3);
  border-radius: .5rem;
  color: var(--white);
  padding: 1rem;
  width: 80%;
`
export const ContactFormInputArea = styled.textarea`
  outline:  none;
  border: none;
  background-color: rgba(0,0, 0, 0.3);
  border-radius: .5rem;
  color: var(--white);
  padding: 1rem;
  width: 80%;
  resize: none;
`
export const ContactFormLabel = styled.label`
 width: 20%;
`
export const ContactFormSubmit = styled.button`
 /* width: 100%; */
  padding: 1rem 3rem;
  outline:  none;
  border: none;
  font-size: 1.2rem;
  color: var(--white);
  border-radius: .5rem;
  cursor: pointer;
  transition: 500ms;
  background-color: var(--primary);
  /* flex: 1; */
  :hover {
    transform: scale(1.1);
    filter: brightness(.7);
  }
`
