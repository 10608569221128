import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AppContainer, BackToTop, BaseLine, Body } from "./config/BaseLine";
import { Header, Background, ProjectsContext } from "./components";
import routes from "./routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import { getProjects } from "./services";

function App() {
  const [hide, setHide] = useState(false);
  const [projects, setProjects] = useState([]);

  // document.addEventListener("scroll", () => {
  //   const doc = document.documentElement || document.body;
  //   doc.scrollTop > 300 ? setHide(true) : setHide(false);
  // });

  useEffect(() => {
    (async () => {
      try {
        const response = await getProjects();
        setProjects(response);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  console.log(projects);

  return (
    <AppContainer>
      <BrowserRouter>
        <ProjectsContext.Provider value={{ projects, setProjects }}>
          <BaseLine />
          <Header />
          <Body>
            <ToastContainer
              theme="dark"
              position="bottom-left"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <Routes>
              {routes.map((prop, index) => (
                <Route element={prop.element} key={index} path={prop.slug} />
              ))}
            </Routes>
            {/* {hide && (
              <BackToTop
                onClick={() => document.documentElement.scrollIntoView()}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: [0, 0, 1] }}
                transition={{ duration: 0.5 }}
              >
                <i className="fa-solid fa-arrow-up-long"></i>
              </BackToTop>
            )} */}
          </Body>
        </ProjectsContext.Provider>
      </BrowserRouter>

      <Background />
    </AppContainer>
  );
}

export default App;
