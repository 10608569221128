import { motion } from "framer-motion";
import styled from "styled-components";
import Background from '../../assets/Background2.webp'

export const Container = styled.div`
  width: 100%;
  height: 80px;
  color: var(--white);
  position: relative;
`
export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

`
export const Navigation = styled.div`
  display: flex;
  width: 80%;
  justify-content: space-between;
  align-items: center;
  z-index: 99;
  position: fixed;
`

export const Icons = styled(motion.span)`
  font-size: 2rem;
  cursor: pointer;
`
export const SoundIcon = styled(motion.span)`
  background-color: rgba(0, 0, 0, 0.4);
  padding: .2rem 1rem;
  font-size: 1rem;
  display: flex;
  gap: .5rem;
  cursor: pointer;
  .on {
    font-weight: bold;
    color: var(--green);
  }
  .off {
    font-weight: bold;
    color: var(--primary);
  }
`
export const NavigationIcons = styled.div`
  display: flex;
  gap: .5rem;
  a {
    font-size: 1.3rem;
    transition: 500ms;
    :hover {
      filter: brightness(0.6);
      transform: scale(1.2);
    }
    :active {
      transform: scale(1.1);
    }
  }
`

export const RoutesContainer = styled(motion.div)`
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  top: 0;
  opacity: 0;
  display: flex;
  font-family: var(--ff-newtype);
  align-items: center;
  font-weight: bold;
  justify-content: center;
  background-color: var(--dark-mid);
  ::before {
    content: "";
    background-position: center;
    background-size: cover;
    background-image: url(${Background});
    width: 100%;
    left: 0;
    height: 100%;
    position: absolute;
    opacity: .4;
  }
  transition: all 500ms linear ;  
`

export const RoutesWrapper = styled.ul`
  gap: 1.5rem;
  display: flex;
  flex-direction: column;
  list-style: none;
`
export const RouteItem = styled(motion.li)`

a {
  &.active{
    color: rgba(255, 255, 255, .4);
    transform: translateY(-20px);
    ::before {
      content: 'You are here!';
      opacity: 1;
      letter-spacing: normal;
    }
  }
  font-size: var(--fs-header);
  text-decoration: none;
  font-weight: bold;
  color: #a92638;
  letter-spacing: .5rem;
  display: block;
  text-align: center;
  gap: 1rem;
  transition: 500ms;
  text-shadow: 3px -4px #6a1722;
  

  :hover {
    transform: translateY(-20px);
    text-shadow: -20px 0px #6a1722, 20px 0px #6a1722;
    transition-delay: 0ms;
    color: #e14b5f;
    /* -webkit-text-stroke: .1px #e14b5f; */
    &.active {
      text-shadow: 0px 0px #6a1722, 0px 0px #6a1722;
      -webkit-text-stroke: 1px rgba(255, 255, 255, 0);
    }
    ::before {
      opacity: 1!important;
      letter-spacing: 2px;
    }
  }

  ::before {
    content: attr(data-text);
    position: absolute;
    pointer-events: none;
    z-index: 1;
    bottom: -20px;
    font-family: var(--ff-play);
    font-size: .25em;
    white-space: nowrap;
    letter-spacing: 100px;
    color: var(--primary);
    opacity: 0;
    transition: 500ms;
    transition-delay: 250ms;
    text-shadow: 0 0 10px var(--primary), 0 0 30px var(--primary), 0 0 90px var(--primary);;
  }
}
`